/* eslint-disable camelcase */
/* eslint-disable react/jsx-fragments */
/* eslint-disable indent */
import React from 'react'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import idx from 'idx'
import mapValues from 'lodash/mapValues'
import groupBy from 'lodash/groupBy'
import omit from 'lodash/omit'
import { RichText } from 'prismic-reactjs'

import kebabCase from 'lodash/kebabCase'
import { theme } from '../../../styles'
import GatsbyLink from '../../../utils/GatsbyLink'
import Space from '../../common/Space'
import Button from '../../common/Button'
import blackCheckIcon from '../../../../static/icons/check-icons/black-check-icon.svg'
import xIcon from '../../../../static/icons/misc-icons/x-icon.svg'
import grayArrowIcon from '../../../../static/icons/arrow-icons/gray-left-arrow-icon.svg'
import Text from '../../common/Text'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% + 300px);
  margin-left: -150px;
  padding: 80px 0px;
  max-width: 100vw;

  @media (max-width: 1050px) {
    width: calc(100% + 20px);
    max-width: unset;
    margin-left: -10px;
    overflow-x: scroll;
    align-items: flex-start;
  }
`

const Header = styled.div`
  max-width: 1000px;
  padding-bottom: 16px;

  @media (max-width: 1050px) {
    padding-bottom: 0px;
    align-self: center;
  }

  @media (max-width: 800px) {
    * {
      text-align: center !important;
    }
  }
`

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 48px;

  @media (max-width: 1050px) {
    padding: 16px;
    overflow-x: scroll;
    width: max-content;
  }
`

const ColumnHeader = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  background-color: ${theme.color.offWhite};
  width: 100%;
  padding: 24px 0px;
  margin-right: 16px;
  border-radius: 6px 6px 0px 0px;
  border-top: 8px solid ${theme.color.purple500};

  @media (max-width: 1050px) {
    max-width: 300px;
  }

  @media (max-width: 800px) {
    max-width: 225px;
  }
`

const EnterpriseColumnHeader = styled(ColumnHeader)`
  border-top: 8px solid ${theme.color.green500};
`

const RowGroup = styled.div`
  &:last-of-type {
    padding-bottom: 32px;
  }
`

const Row = styled.div`
  display: flex;
  padding: 0px 24px 0px 40px;
  justify-content: flex-start;

  @media (max-width: 1050px) {
    padding: 0px 24px;
  }
`

const HeadingRow = styled.div`
  display: flex;
  padding: 0px 24px 0px 40px;
  flex-direction: row;
  z-index: 1;

  @media (max-width: 1050px) {
    padding: 0px 24px;
  }
`

const ButtonRow = styled(HeadingRow)`
  margin-right: 16px;
`

const ButtonWrapper = styled.div`
  background-color: ${theme.color.offWhite};
  padding: 0px 16px 24px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  border-radius: 0px 0px 6px 6px;

  @media (max-width: 800px) {
    max-width: 225px;
    width: 80vw;
  }
`

const TableCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.color.white};
  width: 100%;
  padding-bottom: 0px;
`

const Block = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 0px 16px;
  background-color: ${theme.color.offWhite};
  border-bottom: 1px solid ${theme.color.dropShadow};

  @media (max-width: 1050px) {
    width: 50vw;
    max-width: 300px;
  }

  @media (max-width: 800px) {
    max-width: 225px;
    width: 80vw;
  }
`

const FeatureTextBlock = styled(Block)`
  justify-content: flex-start;
  padding: 8px 32px 8px 0px;
  padding-left: 0px;
  margin-right: 0px;
  background-color: ${theme.color.white};
  p {
    margin-bottom: 0px;
  }
`

const ArrowIcon = styled.img`
  margin-left: 16px;
`

const MobileSwipeText = styled(Text)`
  display: none;

  @media (max-width: 1050px) {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding-right: 24px;
    margin-left: -24px;
  }
`

const BorderedSpace = styled(Space)`
  border-bottom: 1px solid ${theme.color.dropShadow};
`

const BlockText = ({ text, fontFamily, ...rest }) => (
  <FeatureTextBlock {...rest}>
    <Text richText font="smallBody">
      {text}
    </Text>
  </FeatureTextBlock>
)

const Section = ({ title, sections }) => (
  <>
    {sections[title].map((item, i) => {
      const { feature_text } = item

      return (
        // eslint-disable-next-line react/no-array-index-key
        <Row role="row" key={`${title}-${i}`}>
          <BlockText
            text={<RichText render={feature_text.richText} serializeHyperlink={GatsbyLink} />}
            role="cell"
            aria-labelledby={`table-header-${kebabCase(title)}`}
          />
          <>
            <Block role="cell" aria-labelledby={`table-header-${kebabCase(title)}`}>
              {!item.with_managed && !item.managed_column_text ? (
                <img src={xIcon} alt={`${idx(feature_text, __ => __.text)} is not included with managed`} />
              ) : null}
              {item.with_managed ? (
                <img
                  src={blackCheckIcon}
                  alt={`${idx(feature_text, __ => __.text)} is included with managed`}
                  style={{ width: '18px' }}
                />
              ) : (
                <Text smallBodyHeavy>{idx(item.managed_column_text, __ => __.text)}</Text>
              )}
            </Block>
            <BorderedSpace width={16} />
            <Block role="cell" style={{ marginRight: '16px' }} aria-labelledby={`table-header-${kebabCase(title)}`}>
              {!item.with_enterprise && !item.enterprise_column_text ? (
                <img src={xIcon} alt={`${idx(feature_text, __ => __.text)} is not included with enterprise`} />
              ) : null}
              {item.with_enterprise ? (
                <img
                  src={blackCheckIcon}
                  alt={`${idx(feature_text, __ => __.text)} is included with enterprise`}
                  style={{ width: '18px' }}
                />
              ) : (
                <Text smallBodyHeavy>{idx(item.enterprise_column_text, __ => __.text)}</Text>
              )}
            </Block>
          </>
        </Row>
      )
    })}
  </>
)

const FeaturesTable = ({ primary, items }) => {
  const title = <RichText render={primary.title1.richText} serializeHyperlink={GatsbyLink} />
  const managedColumnHeading = primary.managed_column_heading
  const enterpriseColumnHeading = primary.enterprise_column_heading
  const managedButtonText = primary.managed_button_text.text
  const enterpriseButtonText = primary.enterprise_button_text.text
  const anchorId = primary.anchor_id

  const sections =
    items &&
    items[0] &&
    mapValues(
      groupBy(items, val => (idx(val, idxArg => idxArg.feature_section.text) ? val.feature_section.text : 'Features')),
      clist => clist.map(car => omit(car, 'feature_section'))
    )

  return (
    <Wrapper id={anchorId || null}>
      <Header>
        <Text heading2 center>
          {title}
        </Text>
      </Header>
      <TableWrapper role="table">
        <div role="rowgroup" style={{ zIndex: '1' }}>
          <HeadingRow role="row">
            <Space style={{ display: 'flex', flex: '1', paddingRight: '0px' }} role="cell">
              <MobileSwipeText smallBody color="gray700">
                <ArrowIcon src={grayArrowIcon} style={{ marginRight: '16px' }} />
                Swipe to see and compare plans
              </MobileSwipeText>
            </Space>
            <ColumnHeader>
              <Text bodyHeavy center role="columnheader">
                {idx(managedColumnHeading, __ => __.text)}
              </Text>
            </ColumnHeader>
            <EnterpriseColumnHeader role="presentation">
              <Text bodyHeavy center role="columnheader">
                {idx(enterpriseColumnHeading, __ => __.text)}
              </Text>
            </EnterpriseColumnHeader>
          </HeadingRow>
        </div>
        <TableCard>
          {Object.keys(sections).map(key => (
            <RowGroup role="rowgroup" key={key}>
              <Row role="row">
                <Text
                  bodyHeavy
                  role="cell"
                  style={{ flex: '1', paddingTop: '32px', paddingRight: '0px' }}
                  id={`table-header-${kebabCase(key)}`}
                >
                  {key}
                </Text>
                <Space style={{ flex: '1' }} backgroundColor="offWhite" />
                <Space width={16} />
                <Space style={{ flex: '1', marginRight: '16px' }} backgroundColor="offWhite" />
              </Row>
              <Section title={key} sections={sections} />
            </RowGroup>
          ))}
          <Row>
            <Space height={32} style={{ flex: '1', paddingRight: '32px' }} />
            <Space height={32} style={{ flex: '1', padding: '0px 16px' }} backgroundColor="offWhite" />
            <Space height={32} width={16} />
            <Space
              height={32}
              style={{ flex: '1', marginRight: '16px', padding: '0px 16px' }}
              backgroundColor="offWhite"
            />
          </Row>
        </TableCard>
        <ButtonRow>
          <Space style={{ flex: '1', marginRight: '0px', paddingRight: '32px' }} />
          <ButtonWrapper>
            <Button text={managedButtonText} to="#get-started-free" />
          </ButtonWrapper>
          <Space height={32} width={16} />
          <ButtonWrapper>
            <Button text={enterpriseButtonText} to="/enterprise-quote" />
          </ButtonWrapper>
        </ButtonRow>
      </TableWrapper>
    </Wrapper>
  )
}

export default FeaturesTable

export const query = graphql`
  fragment FeaturesTable on PrismicPageDataBodyFeaturesTable {
    ...SliceBase
    items {
      enterprise_column_text {
        ...TextContent
      }
      feature_section {
        ...TextContent
      }
      feature_text {
        ...TextContent
      }
      managed_column_text {
        ...TextContent
      }
      with_enterprise
      with_managed
    }
    primary {
      anchor_id
      enterprise_button_text {
        ...TextContent
      }
      enterprise_column_heading {
        ...TextContent
      }
      managed_button_text {
        ...TextContent
      }
      managed_column_heading {
        ...TextContent
      }
      title1 {
        ...TextContent
      }
    }
  }
`
